import posthog from 'posthog-js';
import { _info } from 'posthog-js/lib/src/utils/event-utils';

import type { AnalyticsEventHandler } from '@tracking/data';

export const ANONYMIZED_OVERRIDE_VALUE = 'anonymous';
export const overridenProperties = ['$ip', '$device_id', 'distinct_id'].reduce(
  (propsMap, propName) => ({ ...propsMap, [propName]: ANONYMIZED_OVERRIDE_VALUE }),
  {}
);
const whitelistedProperties = ['$current_url'];
export const blacklistedProperties = Object.keys(_info.properties()).filter(
  blackistedProperty => !whitelistedProperties.includes(blackistedProperty)
);

export const isIdentified = () => posthog.get_property('identified');

export const posthogCapture: AnalyticsEventHandler = (portalEventName, properties) =>
  posthog.capture(portalEventName, {
    ...properties,
    ...(isIdentified() ? {} : overridenProperties),
  });
