import {
  FetchLike,
  HaapiConfiguration,
  createHaapiFetch,
} from '@curity/identityserver-haapi-web-driver';
import { isEqual } from 'lodash';
import { useCallback } from 'react';

import { Action } from './types';

export type HaapiCaller = <T = any>(
  url: URL,
  method?: string,
  data?: URLSearchParams,
  followRedirects?: boolean
) => Promise<T>;

const getHaapiCallData = (fields?: Action['model']['fields']) => {
  if (!fields) {
    return;
  }
  return new URLSearchParams(fields.map(field => [field.name, field.value]));
};

export const getHaapiCallParams = (action?: Action) => {
  if (!action) {
    throw new Error('No action provided');
  }
  return [
    new URL(action.model.href),
    action.model.method,
    getHaapiCallData(action.model.fields),
  ] as const;
};

export const findActionKind = (actions: Action[], kind: string) =>
  actions.find((action: Action) => action.kind === kind);

const createBaseHaapiUrl = (url: URL, method: string, data: URLSearchParams | undefined) => {
  if (method !== 'GET' || !data) {
    return url.toString();
  }
  const finalUrl = url;
  data.forEach((value, key) => {
    finalUrl.searchParams.set(key, value);
  });
  return finalUrl.toString();
};

const createHaapiCaller = (haapiFetch: FetchLike): HaapiCaller => {
  const callHaapiBase: HaapiCaller = async (url, method = 'GET', data) => {
    const init: Parameters<FetchLike>[1] = { method, body: method !== 'get' ? data : undefined };
    const finalUrl = createBaseHaapiUrl(url, method, data);
    const response = await haapiFetch(finalUrl, init);

    const haapiResponse = response.json();
    return haapiResponse;
  };

  const callHaapi: HaapiCaller = async (url, method, data, followRedirects = true) => {
    const response = await callHaapiBase(url, method, data);
    const action = response.actions?.[0];
    if (action?.template === 'form' && action?.kind === 'redirect' && followRedirects) {
      return callHaapi(
        action.model.href,
        action.model.method,
        getHaapiCallData(action.model.fields)
      );
    }
    return response;
  };

  return callHaapi;
};

// calling createHaapiFetch discards any previously created instance making it unusable
// this may even throw an initialization error when new instance is before the previous one is initialized
//
// that's the reason of making it singleton-like and memoizing it until the config is the same.
const createInstanceManager = <T, A extends unknown[]>(factory: (...args: A) => T) => {
  let instance: T | undefined;
  let lastArgs: A | undefined;
  return {
    get: (...params: A): T => {
      if (!instance || !isEqual(lastArgs, params)) {
        instance = factory(...params);
        lastArgs = params;
      }
      return instance;
    },
    reset: () => {
      instance = undefined;
      lastArgs = undefined;
    },
  };
};

type FetchHaapiConfig = Pick<HaapiConfiguration, 'clientId' | 'tokenEndpoint'>;

export const haapiFetchInstance = createInstanceManager(createHaapiFetch);

export const useHaapiCaller = ({ clientId, tokenEndpoint }: FetchHaapiConfig): HaapiCaller => {
  return useCallback<HaapiCaller>(
    (...args) => {
      const haapiFetch = haapiFetchInstance.get({ clientId, tokenEndpoint });
      const caller = createHaapiCaller(haapiFetch);
      return caller(...args);
    },
    [clientId, tokenEndpoint]
  );
};
